import {createContext, useReducer} from 'react'

import { useRouter } from 'next/router'

const isDev = !process.env.NEXT_PUBLIC_SENTRY_DSN
const Sentry = !isDev ? require('@sentry/nextjs') : null

const initialState = {token: null, user: null, funnels: null, showHits: true, funnelIndex: 0, funnelNodes: null, funnelEdges: null, selectedElements: null, isLoading: false}
const store = createContext(initialState)
const { Provider } = store
export {store}

const MainProvider = ({children}) => {
  const router = useRouter()
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {

      // user
      case 'SET_USER_TOKEN':
        localStorage.setItem('token', action.value)
        return {...state, token: action.value}
      case 'SET_USER':
        Sentry?.setUser({ email: action.value.email })
        gtag('set', 'user_properties', { user_id: action.value.id })
        return {...state, user: action.value}
      case 'SET_LOGOUT_USER':
        router.push(`/`, null, { shallow: true })
        localStorage.removeItem('token')
        return {...state, token: null, user: null}

      // flags
      case 'SET_IS_SAVING':
        return {...state, isSaving: action.value}
      case 'SET_ABOUT_TO_SAVE':
        return {...state, aboutToSave: action.value}
      case 'SET_LOADING':
        return {...state, isLoading: action.value}
      case 'SET_LOADED':
        return {...state, isLoaded: action.value}
      case 'SET_SHOW_HITS':
        return {...state, showHits: action.value}

      // funnel
      case 'SET_SELECTED_ELEMENTS':
        return {...state, selectedElements: action.value}
      case 'SET_FUNNEL_NODES':
        return {...state, funnelNodes: action.value}
        case 'SET_FUNNEL_EDGES':
        return {...state, funnelEdges: action.value}
      case 'SET_INSTANCE':
        return {...state, instance: action.value}

      // funnels
      case 'SET_FUNNELS':
        return {...state, funnels: action.value}
      case 'SET_FUNNEL_INDEX':
        return {...state, funnelIndex: action.value}
      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{state, dispatch}}>{children}</Provider>
}

export {MainProvider}